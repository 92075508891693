import React from "react"
import { Seo, Layout } from "components"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    404 not Found
  </Layout>
)

export default NotFoundPage
